import React, { useEffect, useState } from 'react'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import { Link } from 'react-router-dom'
import { Button,Form } from 'react-bootstrap'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import TerminatedMemberData from './TerminatedMemberData'

const TerminatedMember = () => {
 
  // https://103.50.212.98:4431/api/TerminateMember/post
  // https://103.50.212.98:4431/api/TerminateMember/AccountNo?AccountNo=${accountNo}
  // const { handleSubmit, register, reset, formState: { errors } } = useForm();
  const [users, setUsers] = useState([])
  const [userData, setUserData] = useState(null); // State to store user data (auto fill fields)
  const [accountNo, setAccountNo] = useState(''); // State to store account number

  const [dateError, setDateError] = useState(false);
  const [monthlyDepositError, setMonthlyDepositError] = useState(false);
  const [insuranceError, setInsuranceError] = useState(false);
  const [payableAmountError, setPayableAmountError] = useState(false);
  const [shareError, setShareError] = useState(false);



  const [formData, setFormData] = useState({
    accountNo: '',
    fullName: '',
    date: '',
    reason: '',
    share: '',
    monthlyDeposit: '',
    insurance: '',
    _10AddedAmount: '',
    rd: '',
    fd: '',
    regLoan: '',
    regLoanInterest: '',
    emgLoan: '',
    emgLoanInterest: '',
    totalAmout: '',
    payableAmount: '',
    chequeNo: '',
    chequeDate: '',
    payAccountName: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const calculateSums = (data) => {
    const sum1 = parseFloat(data.share) + parseFloat(data.monthlyDeposit) + parseFloat(data.insurance) + parseFloat(data._10AddedAmount);
    const sum2 = parseFloat(data.rd) + parseFloat(data.fd) + parseFloat(data.regLoan) + parseFloat(data.regLoanInterest) + parseFloat(data.emgLoan) + parseFloat(data.emgLoanInterest);
    
    return {
      totalAmout: sum1 - sum2,
      payableAmount: sum1 - sum2,
    };
  };

  const fetchUserDetails = async (accountNo) => {
    try {
      const res = await axios.get(`https://103.50.212.98:4431/api/TerminateMember/AccountNo?AccountNo=${accountNo}`);
      const userData = res.data.result;
      setUserData(userData);

      const sums = calculateSums(userData);

      setFormData({
        ...formData,
        fullName: userData.fullName,
        share: userData.share,
        monthlyDeposit: userData.monthlyDeposit,
        insurance: userData.insurance,
        _10AddedAmount: userData._10AddedAmount,
        rd: userData.rd,
        fd: userData.fd,
        regLoan: userData.regLoan,
        regLoanInterest: userData.regLoanInterest,
        emgLoan: userData.emgLoan,
        emgLoanInterest: userData.emgLoanInterest,
         totalAmout: sums.totalAmout,
         payableAmount: sums.payableAmount,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateButtonClick = async () => {
    if (!formData.date) {
      setDateError(true);
      return;
    }
    setDateError(false);

    const formattedDate = new Date(formData.date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).replace(/\//g, '-');

    try {
      const response = await axios.get(`https://103.50.212.98:4431/api/TerminateMember/InterestDetails?AccountNo=${formData.accountNo}&userdate=${formData.date}`);
      const { regularLoanInterest, emgLoanInterest, fdAmount, rdAmount } = response.data.result;

      const sums = calculateSums({
        ...formData,
        regLoanInterest: regularLoanInterest,
        emgLoanInterest: emgLoanInterest,
        fd: fdAmount,
        rd: rdAmount,
      });

      setFormData({
        ...formData,
        regLoanInterest: regularLoanInterest,
        emgLoanInterest: emgLoanInterest,
        fd: fdAmount,
        rd: rdAmount,
        totalAmout: sums.totalAmout,
        payableAmount: sums.payableAmount,
      });
    } catch (error) {
      console.error("Error fetching interest details:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form submitted!');
    console.log('Form Data:', formData);

    let hasError = false;

    if (!formData.date) {
      setDateError(true);
      hasError = true;
    } else {
      setDateError(false);
    }

    if (!formData.monthlyDeposit) {
      setMonthlyDepositError(true);
      hasError = true;
    } else {
      setMonthlyDepositError(false);
    }

    if (!formData.insurance) {
      setInsuranceError(true);
      hasError = true;
    } else {
      setInsuranceError(false);
    }

    if (!formData.payableAmount) {
      setPayableAmountError(true);
      hasError = true;
    } else {
      setPayableAmountError(false);
    }

    if (!formData.share) {
      setShareError(true);
      hasError = true;
    } else {
      setShareError(false);
    }

    if (hasError) {
      return;
    }

    try {
      const response = await axios.post('https://103.50.212.98:4431/api/TerminateMember/post', formData);
      console.log('Form data submitted successfully:', response.data);
      alert("Form data submitted successfully");
      setFormData({
        accountNo: '',
        fullName: '',
        date: '',
        reason: '',
        share: '',
        monthlyDeposit: '',
        insurance: '',
        _10AddedAmount: '',
        rd: '',
        fd: '',
        regLoan: '',
        regLoanInterest: '',
        emgLoan: '',
        emgLoanInterest: '',
        totalAmout: '',
        payableAmount: '',
        chequeNo: '',
        chequeDate: '',
        payAccountName: '',
      });
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };




  const fetchUsers = async (url) => {
    try {
      const res = await axios.get(url); // Use axios.get instead of fetch
      const Tdata = res.data; // Axios response data is directly accessible as res.data
      console.log(Tdata, " new loan table data"); // Log the data here to inspect its structure
      setUsers(Tdata); // Set the fetched data to the users state
    } catch (error) {
      console.log(error);
    }
  };

  const API = "https://103.50.212.98:4431/api/TerminateMember/get";

  useEffect(() => {
    fetchUsers(API);
  }, []);



  return (
    <div>
         <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->
   
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>
       
        </div>
        {/* <!-- #END# Search Bar -->
   
   
   
   
  <!-- Top Bar --> */}
       <AdminTopBar/>
        {/* <!-- #Top Bar --> */}


        
{/* sideBar */}


<AdminSideBar/>
{/* end sideBar */}

        <section className="content">
             <Link to="/AdminDashBoard">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">
            
            </div>

         
              {/* form starts */}
            {/* <!-- Masked Input --> */}

 
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="DedictionForm">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2> सभासदत्व रद्द झालेल्या सभासदांचा गोषवारा</h2>
                  </div>
                  <div className="body">
                    <div className="demo-masked-input">
                      <div className="row clearfix">
                       
                       
                     

                         <div className="col-md-8">
                          <b>Account Number (खाते क्रमांक)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                                    <div className="col-4">
                                        <input className="form-control date"
                                        name='accountNo'
                                        type="number" placeholder="Enter Account Number (खाते क्रमांक)"
                                        value={formData.accountNo}
                                        onChange={handleInputChange}
                                        />
                                   </div>
                             </div>
                          </div>
                        </div> 

                        <button type="button"
                          onClick={() => fetchUserDetails(formData.accountNo)}
                        className="btn bg-indigo ">Search</button> 
                    



                        <div className="col-md-8">
                          <b>सभासदाचे नाव</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="text"
                                name='fullName'
                                className="form-control date"
                                placeholder="सभासदाचे नाव"
                                value={formData.fullName}
                                readOnly
                              />
                            </div>
                          </div>
                        </div> 


                       


                        <div className="col-md-8">
                          <b>कारण</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="text"
                                className="form-control date"
                                name='reason'
                                placeholder='कारण'
                                value={formData.reason}  
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div> 




                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          {/* <b>Current Deduction</b> <br/> <br/> */}
                          <b>भाग (शेअर्स)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className={`form-control ${shareError ? 'is-invalid' : ''}`}
                                name='share'
                                placeholder="भाग (शेअर्स)"
                                value={formData.share}
                                readOnly
                              />
                              
                            </div>
                          </div>
                          {shareError && <p style={{ color: "red" }}>Share is required</p>}
                        </div> 


                        <div className="col-md-8">
                          {/* <b>Current Deduction</b> <br/> <br/> */}
                          <b>Monthly Deposite</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className={`form-control ${monthlyDepositError ? 'is-invalid' : ''}`}
                                name='monthlyDeposit'
                                placeholder="भाग (शेअर्स)"
                                value={formData.monthlyDeposit}
                                readOnly
                              />
                              
                            </div>
                          </div>
                          {monthlyDepositError && <p style={{ color: "red" }}>Monthly Deposite is required</p>}
                        </div> 



                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          {/* <b>Current Deduction</b> <br/> <br/> */}
                          <b>Insurence</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className={`form-control ${insuranceError ? 'is-invalid' : ''}`}
                                name='insurance'
                                placeholder="Enter insurance"
                                value={formData.insurance}
                                readOnly
                               />
                              
                            </div>
                          </div>
                          {insuranceError && <p style={{ color: "red" }}>Insurence is required</p>}
                        </div> 

                        <div className="col-md-8">
                          <b>10 Multiply Added Amount</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                name='_10AddedAmount'
                                className="form-control date"
                                placeholder="Enter 10 Multiply Added Amount"
                                value={formData._10AddedAmount}
                                readOnly
                              />
                            </div>
                          </div>
                        </div> 




                        <div className="col-md-8">
                          <b>दिनांक</b>
                          <div className="input-group" style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="date"
                                name='date'
                                className={`form-control ${dateError ? 'is-invalid' : ''}`}
                                value={formData.date}
                                onChange={handleInputChange}
                              />
                            </div>
                            <button
                                  type="button"
                                  className="btn bg-indigo"
                                  style={{ marginLeft: '10px' }}
                                  onClick={handleDateButtonClick}
                                >
                                  Date
                                </button>
                          </div>
                          {dateError && <p style={{ color: "red" }}>Date is required</p>}
                        </div> 



                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          
                          <b>सभासद बचत योजना (RD)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                name='rd'
                                placeholder="सभासद बचत योजना (RD)"
                                value={formData.rd}
                                readOnly
                                />
                              
                            </div>
                          </div>
                        </div> 


                      
                        <div className="col-md-8">
                          
                          <b>सभासद बचत योजना (FD)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                name='fd'
                                placeholder="सभासद बचत योजना (FD)"
                                value={formData.fd}
                                readOnly
                                />
                              
                            </div>
                          </div>
                        </div>



                        <div className="col-md-8">
                          <b>Regular Loan Interest</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                name='regLoanInterest'
                               placeholder='Enter Regular Loan Interest'
                               value={formData.regLoanInterest}
                               readOnly
                               />
                            </div>
                          </div>
                        </div> 


                        <div className="col-md-8">
                          <b>Emergancy Loan Interest</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                name='emgLoanInterest'
                               placeholder='Enter Emergancy Loan Interest'
                               value={formData.emgLoanInterest}
                                readOnly
                              />
                            </div>
                          </div>
                        </div> 



                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">                         
                          <b>Regular Loan</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                name='regLoan'
                                placeholder="Enter regularLoan"
                                value={formData.regLoan}
                                readOnly
                               />
                              
                            </div>
                          </div>
                        </div>


                        <div className="col-md-8">
                          <b>Emergancy Loan</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                name='emgLoan'
                               placeholder='Enter Emergancy Loan'
                               value={formData.emgLoan}
                                readOnly
                              />
                            </div>
                          </div>
                        </div> 




                        <div className="col-md-8">
                          <b>Total Amount</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                name='totalAmout'
                               placeholder='Enter total amount'
                               value={formData.totalAmout}
                               readOnly/>
                            </div>
                          </div>
                        </div> 


                        <div className="col-md-8">
                          <b>Payable Amount</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className={`form-control ${payableAmountError ? 'is-invalid' : ''}`}
                                name='payableAmount'
                               placeholder='Enter Payable Amount'
                               value={formData.payableAmount}
                               readOnly
                              />
                            </div>
                          </div>
                          {payableAmountError && <p style={{ color: "red" }}>Payable Amount is required</p>}
                        </div> 


                        <div className="col-md-8">
                          <b>Cheque Number</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                name='chequeNo'
                               placeholder='Enter Cheque Number'
                               value={formData.chequeNo}
                               onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div> 


                        <div className="col-md-8">
                          <b>Cheque Date</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="date"
                                name='chequeDate'
                                className="form-control date"
                                value={formData.chequeDate}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div> 
                       
                      
                        <div className="col-md-8">
                          <b>Pay Account Name</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="text"
                                name='payAccountName'
                                className="form-control date"
                               placeholder='Enter Pay To'
                               value={formData.payAccountName}
                               onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div> 
                       
                        

                      

                                  
                      </div>
                      <Button type="submit"  variant='primary' className="btn btn-block btn-lg waves-effect">Submit</Button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            </Form.Group>
            </Form>
            {/* <!-- #END# Masked Input --> */}
            {/* form ends */}


               {/* Table start */}

               <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Terminated Member Table</h2>

                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                        
                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}



                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >
                          
                          <tbody>
                            <TerminatedMemberData Users={users} />

                      
                          </tbody>
                        </table>
                      
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Table end */}



          </div>
        </section>  
      </div>
    </div>
  )
}

export default TerminatedMember
