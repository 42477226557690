
import React from 'react';
import { Link } from 'react-router-dom';
import AdminSideBar from '../../AdminSideBar/AdminSideBar';
import AdminTopBar from '../../AdminTopBar/AdminTopBar';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const DeductionListGenerate = () => {
  const { handleSubmit, register, formState: { errors } } = useForm();

  const onSubmit = async (data) => {  
    try {  
      const response = await axios.get(`https://103.50.212.98:4431/api/DeductionList/DeductionReport?Reportdate=${data.date}`);  
      const jsonData = response.data.result;  
  
      const formatDate = (date) => {  
        const d = new Date(date);  
        const day = String(d.getDate()).padStart(2, '0');  
        const month = String(d.getMonth() + 1).padStart(2, '0');  
        const year = d.getFullYear();  
        return `${year}-${month}-${day}`;  
      };  
  
      const formattedData = jsonData.map(item => {  
        if (item.date) {  
          item.date = formatDate(item.date); // Apply the date formatting  
        }  
        return item;  
      });  
  
      // Convert JSON to worksheet  
      const worksheet = XLSX.utils.json_to_sheet(formattedData);  
      const workbook = XLSX.utils.book_new();  
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Deduction Report');  
  
      // Write workbook to binary  
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });  
  
      // Create Blob and save  
      const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });  
      saveAs(blob, 'DeductionReport.xlsx');  
    } catch (error) {  
      console.error('Error fetching the report:', error);  
    }  
  };
  return (
    <div>
      <div className="theme-red">
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>
        </div>
        <AdminTopBar />
        <AdminSideBar />
        <section className="content">
          <Link to="/AdminDashBoard"><h4>DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header"></div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="DedictionForm">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="card">
                      <div className="header">
                        <h2>Deduction List Generate</h2>
                      </div>
                      <div className="body">
                        <div className="demo-masked-input">
                          <div className="row clearfix">
                            <div className="col-md-8">
                              <b>Select Start Date</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">event_note</i>
                                </span>
                                <div className="form-line">
                                  <Form.Control
                                    type="date"
                                    className="form-control time24"
                                    {...register("date", { required: "Please enter Start Date" })}
                                  />
                                </div>
                              </div>
                              <p style={{ color: "red" }}>{errors?.date?.message}</p>
                            </div>
                            <Button type="submit" variant='primary' className="btn btn-block btn-lg waves-effect">Report</Button>
                            {/* <Button type="button" className="btn bg-indigo btn-block btn-lg waves-effect" data-toggle="modal" data-target="#exampleModal">Print</Button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </Form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DeductionListGenerate;

