import React from "react";
import { useNavigate } from "react-router-dom";
import "./AdminTopBar.css";

const AdminTopBar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear user-info from local storage
    localStorage.removeItem('user-info');

    // Navigate to the home page or login page
    navigate('/');
  };

  return (
    <div>
      {/* <!-- Top Bar --> */}
      <nav className="navbar" style={{ backgroundColor: "#03a9f4", height: "70px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {/* <img src="/images/srtlogo.jpeg" style={{ height:"90px", width:"90px", borderRadius:"50px"}}/> */}

        <a style={{ fontSize: "20px", textAlign: "center" }}>
          <span style={{ color: "#3f51b5", marginTop: "10px" }} className="logo">स्वामी रामानंद तीर्थ मराठवाडा विद्यापीठ कर्मचारी सहकारी पतसंस्था मर्यादित,</span>
          <span style={{ color: "#fff", marginTop: "10px" }} className="text-white wishnupuri">विष्णुपुरी, नांदेड</span>
        </a>
        <div onClick={handleLogout} style={{ position: "absolute", right: "20px", display: "flex", alignItems: "center", color: "#fff", cursor: "pointer" }}>
          <i className="material-icons" style={{ marginRight: "5px" }}>exit_to_app</i>
          <span>Logout</span>
        </div>
      </nav>
      {/* <!-- #Top Bar --> */}
    </div>
  );
};

export default AdminTopBar;
