import React, { useEffect, useState } from 'react'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import { Link } from 'react-router-dom'
import AddRDModal from './AddRDModal'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'react-bootstrap'
// import { Submit } from '../../Service';
import axios from 'axios'
import AddRDData from './AddRDData'

const AddRD = () => {

  const [users, setUsers] = useState([])
  const [userData, setUserData] = useState(null); // State to store user data (auto fill fields)
  const [accountNo, setAccountNo] = useState(''); // State to store account number
  const [rdAmountError, setRdAmountError] = useState(false);
  const [rdDateError, setRdDateError] = useState(false);
  
  // Add a state to hold form data
 
  const [rdno, setRdno] = useState('');
  const [formData, setFormData] = useState({
    accountNo: '',
    fullName: '',
    rdno: '',
    rdmonthlyAmount: '',
    startDate: '',
    // receiptNo: '',
    // chequeNumber: '',
    // fdId: 0,
    // fdYear: new Date().toISOString(),
    // fdCloseDate: new Date().toISOString(),
    // isDelete: true,
    // changeInterestId: 0,
    // interestAoumnt: 0,
    // totalFdamount: 0,
  });

  useEffect(() => {
    const fetchRdNo = async () => {
      try {
        const response = await axios.get('https://103.50.212.98:4431/api/RdDetails/RDNo');
        const { result } = response.data;
        setRdno(result);
      } catch (error) {
        console.log('Error fetching FD Certificate Number:', error);
      }
    };

    fetchRdNo(); // Trigger the API call when component mounts
  }, []); // Empty dependency array means this effect runs only once after the initial render

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handlerdNoChange = (e) => {
    const { value } = e.target;
    setRdno(value); // Update the fdCertificateNo state directly
  };

  const fetchUserDetails = async (accountNo) => {
    try {
      const res = await axios.get(`https://103.50.212.98:4431/api/RdDetails/SearchButton?AccountNo=${accountNo}`);
      const userData = res.data.result;
      setUserData(userData);
      // Update accountName based on fetched user data
      setFormData({
        ...formData,
        fullName: userData.fullName,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Check if RD monthly amount is empty
    if (!formData.rdmonthlyAmount) {
      setRdAmountError(true);
    } else {
      setRdAmountError(false);
    }
  
    // Check if RD date is empty
    if (!formData.startDate) {
      setRdDateError(true);
    } else {
      setRdDateError(false);
    }
  
    // If either RD monthly amount or RD date is empty, exit the function
    if (!formData.rdmonthlyAmount || !formData.startDate) {
      return;
    }
  
    // Validation to ensure only one of Receipt Number or Cheque Number is filled
    if (formData.receiptNo && formData.chequeNumber) {
      alert("Please fill either Receipt Number or Cheque Number, not both.");
      return;
    }
  
    try {
      // Convert rdno to string
      const rdnoAsString = rdno.toString();
      // Add rdno as a string to the formData object
      const formDataWithRdNoAsString = {
        ...formData,
        rdno: rdnoAsString,
      };
  
      const response = await axios.post('https://103.50.212.98:4431/api/RdDetails/post', formDataWithRdNoAsString);
      console.log('Form data submitted successfully:', response.data);
      alert("Form data submitted successfully");
      
      // Reset the form fields to initial empty state
      setFormData({
        accountNo: '',
        fullName: '',
        rdno: '',
        rdmonthlyAmount: '',
        startDate: '',
        receiptNo: '',
        chequeNumber: '',
      });
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };
  


  // table data code
  const fetchUsers = async (url) => {
    try {
      const res = await axios.get(url); // Use axios.get instead of fetch
      const Tdata = res.data; // Axios response data is directly accessible as res.data
      console.log(Tdata, " Add FD table data"); // Log the data here to inspect its structure
      setUsers(Tdata); // Set the fetched data to the users state
    } catch (error) {
      console.log(error);
    }
  };

  const API = "https://103.50.212.98:4431/api/RdDetails/get";

  useEffect(() => {
    fetchUsers(API);
  }, []);

   // Prevent accidental value changes on scroll
   const handleScroll = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
 


  return (
    <div>
           <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->
   
     
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>
       
        </div>
        {/* <!-- #END# Search Bar -->
      
  <!-- Top Bar --> */}
       <AdminTopBar/>
        {/* <!-- #Top Bar --> */}
       
{/* sideBar */}

<AdminSideBar  />
{/* end sideBar */}

        <section className="content">
             <Link to="/AdminDashBoard">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">
            
            </div>

          
              {/* form starts */}
            {/* <!-- Masked Input --> */}

            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="DedictionForm">


            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2> ADD RD</h2>
                  </div>
                  <div className="body">
                    <div className="demo-masked-input">
                      <div className="row clearfix">
                       
                                             
                        <div className="col-md-8">
                          <b>Account Number (खाते क्रमांक)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                name='accountNo'
                                className="form-control date"
                                placeholder="Enter Account Number"
                                value={formData.accountNo}
                                onChange={handleInputChange}
                              />
                              <button
                               onClick={() => fetchUserDetails(formData.accountNo)}
                              type="button" className="btn bg-indigo btn-block btn-lg waves-effect primaryButton secondaryButton">Search</button>
                            </div>
                          </div>
                        </div>
                        {/* <button type="button" className="btn btn-primary ">Search</button> */}


                        <div className="col-md-8">
                          <b>Full Name (पूर्ण नाव)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="text"
                                name='fullName'
                                className="form-control date"
                                placeholder="Enter Full Name (पूर्ण नाव)"
                                value={formData.fullName}
                                readOnly

                              />
                            </div>
                          </div>
                        </div>



                        <div className="col-md-8">
                          <b>RD Number</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="text"
                                name='rdno'
                                className="form-control date"
                                placeholder='Enter RD Number'
                                value={rdno} // Bind input value directly to the state
                                onChange={handlerdNoChange} // Use the new onChange handler
                                readOnly // Mark input field as read-only (optional based on your requirement)
                             
                              />
                            </div>
                          </div>
                        </div>


                       <div className="col-md-8">
                          <b>RD Amount Monthly(RD रक्कम)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                    type="number"
                                    name='rdmonthlyAmount'
                                    // className="form-control date"
                                    className={`form-control date ${rdAmountError ? 'is-invalid' : ''}`}
                                    value={formData.rdmonthlyAmount}
                                    onChange={handleInputChange}
                                  />
                            </div>
                          </div>
                          {rdAmountError && <p style={{ color: 'red' }}>RD Amount is required</p>}
                        </div>



                        <div className="col-md-8">
                          <b>RD Date (RD तारीख)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                             
                                <input
                                    type="date"
                                    name='startDate'
                                    className={`form-control date ${rdDateError ? 'is-invalid' : ''}`}
                                    value={formData.startDate}
                                    onChange={handleInputChange}
                                  />
                            </div>
                          </div>
                          {rdDateError && <p style={{ color: 'red' }}>RD Date is required</p>}
                        </div>


                             <div className="col-md-8">
                          <b>Receipt Number</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                name='receiptNo'
                                className="form-control date"
                                placeholder='Enter Receipt Number'
                                // {...register("receiptNo")}
                                value={formData.receiptNo}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div> 



                        <div className="col-md-8">
                          <b>Cheque Number</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="text"
                                name='chequeNumber'
                                className="form-control date"
                                placeholder='Enter Receipt Number'
                                // {...register("receiptNo")}
                                value={formData.chequeNumber}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div> 


                      {/* <button type="button" className="btn bg-indigo btn-block btn-lg waves-effect">Submit</button> */} 
                        <Button type="submit" variant='primary' className="btn btn-block btn-lg waves-effect">Submit</Button>
                     
                     
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </Form.Group>
            </Form>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}


  {/* Table start */}

  <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Add RD Table</h2>

                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                        
                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}



                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >
                          
                          <tbody>
                            <AddRDData Users={users} />

                      
                          </tbody>
                        </table>
                       
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         {/*  Table end */}


          </div>
        </section>
      </div>
    </div>
  )
}

export default AddRD
