import React from 'react';  
import CloseFDmodal from './CloseFDmodal';  

const CloseFDData = ({ fdDetails, handleEdit, formData, handleInputChange }) => {  
  return (  
    <div>  
      <table className="table table-bordered table-striped table-hover dataTable js-exportable">  
        <thead>  
          <tr>  
            <th>Sr.No</th>  
            <th>ID</th>  
            <th>CertificateNo</th>  
            <th>Account Number</th>  
            <th>Account Name</th>  
            <th> FD Amount</th>  
            <th>fFD Date</th>  
            <th>FD Close Date</th>  
            <th>Reciept NUmber</th>  
            <th>Cheque Number</th>  
            <th>Total Fd Amount</th>  
            <th>Action</th>  
          </tr>  
        </thead>  
        <tbody>  
          {fdDetails.length === 0 ? (  
            <tr>  
              <td colSpan="12">No data available</td>  
            </tr>  
          ) : (  
            fdDetails.map((details, index) => (  
              <tr key={details.fdId}>  
                <td>{index + 1}</td>  
                <td>{details.fdId}</td>  
                <td>{details.fdCertificateNo}</td>  
                <td>{details.accountNo}</td>  
                <td>{details.accountName}</td>  
                <td>{details.fdAmount}</td>  
                <td>{details.fdDate}</td>  
                <td>{details.fdCloseDate}</td>  
                <td>{details.reciptNo}</td>  
                <td>{details.chequeNo}</td>  
                <td>{details.totalFdamount}</td>  
                <td>  
                  <CloseFDmodal   
                    formData={formData}   
                    handleInputChange={handleInputChange}   
                    handleEdit={() => handleEdit(details.fdId)}  
                    disabled={!!details.fdCloseDate} // Disable if fdCloseDate has a value  
                  />  
                </td>  
              </tr>  
            ))  
          )}  
        </tbody>  
      </table>  
    </div>  
  );  
};  

export default CloseFDData;