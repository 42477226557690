import React, { useEffect, useState } from 'react'
import AdminTopBar from '../AdminTopBar/AdminTopBar'
import AdminSideBar from '../AdminSideBar/AdminSideBar'
import { Link } from 'react-router-dom'
import NotificationModal from './NotificationModal'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import NotificationData from './NotificationData'

const Notification = () => {
  const [users, setUsers] = useState([]);
  const [subject, setSubject] = useState('');
  const [date, setDate] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      notificationId: 0,
      subject: subject,
      date: date,
      isDelete: false
    };

    try {
      const response = await axios.post('https://103.50.212.98:4431/api/Notification/post', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log(response.data);
      alert("notification submited successfully")

        // Reset form fields
        setSubject('');
        setDate('');
      // Handle the response as needed
    } catch (error) {
      console.error('Error posting data:', error);
      alert("Failed to submit notification");
      // Handle the error as needed
    }
  };




  // table data code
  const fetchUsers = async (url) => {
    try {
      const res = await axios.get(url); // Use axios.get instead of fetch
      const Tdata = res.data; // Axios response data is directly accessible as res.data
      console.log(Tdata, " notification file data"); // Log the data here to inspect its structure
      setUsers(Tdata); // Set the fetched data to the users state
    } catch (error) {
      console.log(error);
    }
  };

  const API = "https://103.50.212.98:4431/api/Notification/get";

  useEffect(() => {
    fetchUsers(API);
  }, []);


 
  return (
    <div>
        <div className="theme-red">
      
   
  {/* <!-- Search Bar -->  */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>
       
        </div>
        {/* <!-- #END# Search Bar -->  
   
  <!-- Top Bar --> */}
       <AdminTopBar/>
        {/* <!-- #Top Bar --> */}
     
{/* sideBar */}
<AdminSideBar/>
{/* end sideBar */}

        <section className="content">
             <Link to="/AdminDashBoard">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">
            
            </div>


          
              {/* form starts */}
            {/* <!-- Masked Input --> */}


            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Notice Board</h2> 
                  </div>
                  <form onSubmit={handleSubmit}>
                  <div className="body">
                    <div className="demo-masked-input">
                      <div className="row clearfix">
                       
                                             



                        <div className="col-md-8">
                          <b>Subject</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="text"
                                className="form-control date"
                                placeholder="Enter Interest Type"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>



                        <div className="col-md-8">
                          <b>Date</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="date"
                                className="form-control date"
                                placeholder='Enter Rate of Interest'
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>


                        <Button type="submit"  variant='primary' className="btn btn-block btn-lg waves-effect ">Submit</Button>

                      </div>
                    </div>
                  </div>
                  </form>
                </div>
              </div>
            </div>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}




  {/* Table start */}

  <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Notice Board Table</h2>

                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                        
                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}



                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >
                          
                          <tbody>
                            <NotificationData Users={users} />

                      
                          </tbody>
                        </table>

                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         {/*  Table end */}




          </div>
        </section>
      </div>
    </div>
  )
}

export default Notification
