import React, { useEffect, useState } from 'react'
// import AdminTopBar from '../../AdminTopBar/AdminTopBar'
// import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import { Link } from 'react-router-dom'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import { RiFolderOpenLine } from "react-icons/ri";
import { FcSimCardChip, FcCancel } from "react-icons/fc";
import ThirdPartyFormModal from './ThirdPartyFormModal';
import { Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
// import { Submit } from '../../Service';
import axios from 'axios';
import UserData from './UserData';


const ThirdPartyForm = () => {
  const [users, setUsers] = useState([]);
  const [balanceData, setBalanceData] = useState(null);
  const [headTypes, setHeadTypes] = useState([]);

  const [formData, setFormData] = useState({
    partyName: '',
    fullName: '',
    amount: '',
    date: '',
    chequeNo: '',
    receiptNo: '',
    transaction: '',
    notes: '',
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchBalanceData = async () => {
      try {
        const response = await axios.get('https://103.50.212.98:4431/api/ThirdParty/OpeningCLosingBalance');
        const { data } = response;
        console.log('Third Party card data', data);
        setBalanceData(data.result);
      } catch (error) {
        console.error('Error fetching balance data:', error);
      }
    };

    fetchBalanceData();
  }, []);

  useEffect(() => {
    const fetchHeadTypes = async () => {
      try {
        const response = await axios.get('https://103.50.212.98:4431/api/HeadType/get');
        const { data } = response;
        console.log('Head Types:', data);
        setHeadTypes(data.result);
      } catch (error) {
        console.error('Error fetching head types:', error);
      }
    };

    fetchHeadTypes();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let newErrors = {};
    if (!formData.amount) newErrors.amount = 'Please enter amount';
    if (!formData.date) newErrors.date = 'Please enter date';
    return newErrors;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      axios.post('https://103.50.212.98:4431/api/ThirdParty/post', formData)
        .then((response) => {
          console.log('Response:', response.data);
          setFormData({
            partyName: '',
            fullName: '',
            amount: '',
            date: '',
            chequeNo: '',
            receiptNo: '',
            transaction: '',
            notes: '',
          });
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  const fetchUsers = async (url) => {
    try {
      const res = await fetch(url);

      const Tdata = await res.json();
      console.log(Tdata, "third party table data"); // Log the data here to inspect its structure
      setUsers(Tdata); // Set the fetched data to the users state
    } catch (error) {
      console.log(error);
    }
  };



  const API = "https://103.50.212.98:4431/api/ThirdParty/get"
  useEffect(() => {
    fetchUsers(API)
  }, [])

 
  return (
    <div>
      <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->
  
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>

        </div>
        {/* <!-- #END# Search Bar -->
  
   
  <!-- Top Bar --> */}
        <AdminTopBar />
        {/* <!-- #Top Bar --> */}


        <AdminSideBar />
        {/* end sideBar */}

        <section className="content">
          <Link to="/AdminDashboard">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">

            </div>



            {/* <!-- Widgets --> */}
             {/* Display Balance Information */}
             {balanceData && (
            <div className="row clearfix">

              <Link to="totalshareamount">
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div className="info-box bg-pink hover-expand-effect">
                    <div className="icon">
                      <RiFolderOpenLine style={{ fontSize: '70' }} />
                      {/* <i className="material-icons">playlist_add_check</i> */}
                    </div>
                    <div className="content">
                      {/* <div className="text">Opening Balance:573351.6700000000
                        41909515857696533203125</div> */}
                        <div className="text">
                        Opening Balance: {balanceData.openingBalance}
                      </div>
                      {/* <p>View Detail</p> */}
                      <div
                        className="number count-to"
                        data-from="0"
                        data-to="125"
                        data-speed="15"
                        data-fresh-interval="20"
                      ></div>
                    </div>
                  </div>
                </div>
              </Link>



              <Link to="monthlybalance">
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div className="info-box bg-cyan hover-expand-effect">
                    <div className="icon">
                      <FcSimCardChip style={{ fontSize: '70' }} />
                      {/* <i className="material-icons">monetization_on</i> */}
                    </div>
                    <div className="content">
                      <div className="text">Credit Amount : {balanceData.creditAmount}</div>
                      <div className="text">Debit Amount : {balanceData.debitAmount}</div>
                      {/* <p>View Detail</p> */}
                      <div
                        className="number count-to"
                        data-from="0"
                        data-to="257"
                        data-speed="1000"
                        data-fresh-interval="20"
                      ></div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="dashboardinsurence">
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div className="info-box bg-light-green hover-expand-effect">
                    <div className="icon">
                      <FcCancel style={{ fontSize: '70' }} />
                      {/* <i className="material-icons">enhanced_encryption</i> */}
                    </div>
                    <div className="content">
                      <div className="text">Closing Balance :{balanceData.closingBalance}</div>
                      {/* <p>View Detail</p> */}
                      <div
                        className="number count-to"
                        data-from="0"
                        data-to="243"
                        data-speed="1000"
                        data-fresh-interval="20"
                      ></div>
                    </div>
                  </div>
                </div>
              </Link>




            </div>
             )}
             {/* End Display Balance Information */}
            {/* <!-- #END# Widgets --> */}


            {/* form starts */}
            
            {/* <!-- Masked Input --> */}



            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Add Third Party Payment Form</h2>
                  </div>
                  <div className="body">
                    <div className="demo-masked-input">
                      <div className="row clearfix">
                        <Form onSubmit={onSubmit}>


                          {/* input */}
                          <div className="col-md-8">
                            <b>Select Party Name</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                {/* dropdown form start */}
                                <div className="col-4">
                                <select id="partyName" name="partyName" type="text"
                                     value={formData.partyName}
                                     onChange={handleChange}
                                    className="form-control">
                                    {headTypes.map((head) => (
                                      <option key={head.headId} value={head.headType}>
                                        {head.headType}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                {/* dropdown form ends */}
                              </div>
                            </div>
                          </div>
                          {/* input */}


                       


                          <div className="col-md-8">
                            <b>Full Name</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  name='fullName'
                                  type="text"
                                  value={formData.fullName}
                                  onChange={handleChange}
                                  className="form-control date"
                                  placeholder="Enter Full Name"
                                />
                              </div>
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <Form.Group className="mb-3" controlId="thirdPartyField">
                                  <Form.Control
                                    name='amount'
                                    type="number"
                                    className="form-control date"
                                    placeholder="Enter Amount"
                                    // {...register("amount", { required: "please enter Amount" },{ pattern: /^[0-9]*$/ })}
                                    value={formData.amount}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            {/* <p style={{ color: "red" }}>{errors?.amount?.message}</p> */}
                            {errors.amount && <p style={{ color: 'red' }}>{errors.amount}</p>}
                          </div>




                          <div className="col-md-8">
                            <b>Date</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <Form.Group className="mb-3" controlId="thirPartyDateField">
                                  <Form.Control
                                    type="date"
                                    name='date'
                                    className="form-control date"
                                    // {...register("date", { required: "please enter Date" })}
                                    value={formData.date}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            {/* <p style={{ color: "red" }}>{errors?.date?.message}</p> */}
                            {errors.date && <p style={{ color: 'red' }}>{errors.date}</p>}
                          </div>




                          <div className="col-md-8">
                            <b>Cheque Number</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="text"
                                  name='chequeNo'
                                  value={formData.chequeNo}
                                  onChange={handleChange}
                                  className="form-control date"
                                  placeholder="Enter Cheque Number"
                                />
                              </div>
                            </div>
                          </div>




                          <div className="col-md-8">
                            <b>Receipt Number</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='receiptNo'
                                  value={formData.receiptNo}
                                  onChange={handleChange}
                                  className="form-control date"
                                  placeholder="Enter Receipt Number"
                                />
                              </div>
                            </div>
                          </div>




                          <div className="col-md-8">
                            <b>Transaction</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">

                                <div className="col-4">
                                  <select name="transaction"
                                     value={formData.transaction}
                                     onChange={handleChange}
                                    className="form-transaction">

                                    <option value="debit">Debit</option>
                                    <option value="credit">Credit</option>


                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>





                          <div className="col-md-8">
                            <b>Notes</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                {/* <input
                                type="number"
                                className="form-control date"
                                placeholder="Enter Receipt Number"
                              /> */}
                                <div className="col-4">
                                  <textarea className="form-control" id="notes"
                                    type="text"
                                    name="notes"
                                    value={formData.notes}
                                    onChange={handleChange}
                                    rows="2" spellCheck="true"></textarea>
                                </div>
                              </div>
                            </div>
                          </div>



                          <Button type="submit" className="btn bg-indigo btn-block btn-lg waves-effect">Submit</Button>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}



            {/* Table start */}

            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>VIEW PARTY DATA</h2>

                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                     

                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >
                        
                          <tbody>
                            <UserData Users={users} />

                           
                          </tbody>
                        </table>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Table end */}

          </div>
        </section>
      </div>
    </div>
  )
}

export default ThirdPartyForm
